import { useState } from 'react';
import { useRouter } from 'next/router';
import { BoxProps } from '@chakra-ui/react';

import { useUnreadCount } from '@arena-labs/chat';
import { HeadsetIcon, HomeIcon, ProgressIcon } from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';
import { IfWearable } from '@strive/wearable';

import { useStriveChatContext } from '../chat/chat-provider';
import { FooterLink } from '../layout/footer-link';
import { FooterNav } from '../layout/footer-nav';
import { FooterNotification } from '../layout/footer-notification';
import { useFeatureUpdateIndicator } from '../version-update/feature-update-indicator';

export type LaunchpadFooterProps = {
  sticky?: boolean;
  tabs?: React.ReactNode;
} & BoxProps;

export function LaunchpadFooter({
  sticky,
  tabs,
  ...props
}: LaunchpadFooterProps) {
  const router = useRouter();
  const getInitialPage = () => {
    if (router.pathname === '/') {
      return 'Home';
    } else if (router.pathname === '/chat') {
      return 'Guide';
    } else if (router.pathname.startsWith('/progress')) {
      return 'Progress';
    } else {
      return 'Home';
    }
  };
  const [currentPage, setCurrentPage] = useState(getInitialPage());

  const { data: user } = useUserProfile();
  const { channel } = useStriveChatContext();
  const unreadCount = useUnreadCount(channel);

  const enableChat = Boolean(user?.profile.channel_name_with_launchpad);

  const newIndicator = {
    Launchpad: useFeatureUpdateIndicator('Launchpad'),
    OnboardGuide: useFeatureUpdateIndicator('OnboardGuide'),
    Data: useFeatureUpdateIndicator('Data'),
  };

  const handleNavigate = (page: string) => {
    setCurrentPage(page);
    const href = {
      Home: '/',
      Guide: '/chat',
      Progress: '/progress',
    }[page];

    if (href) {
      router.push(href, undefined, { shallow: true });
    }
  };

  return (
    <FooterNav tabs={tabs} sticky={sticky} {...props}>
      <FooterLink
        label="Home"
        icon={HomeIcon}
        isCurrent={currentPage === 'Home'}
        onNavigate={handleNavigate}
      >
        <FooterNotification id="Home" isNew={newIndicator.Launchpad} />
      </FooterLink>

      {enableChat && (
        <FooterLink
          label="Guide"
          icon={HeadsetIcon}
          isCurrent={currentPage === 'Guide'}
          onNavigate={handleNavigate}
        >
          <FooterNotification
            id="chat"
            count={unreadCount}
            isNew={newIndicator.OnboardGuide}
          />
        </FooterLink>
      )}
      <IfWearable>
        <FooterLink
          label="Progress"
          icon={ProgressIcon}
          isCurrent={currentPage === 'Progress'}
          onNavigate={handleNavigate}
        >
          <FooterNotification id="biometrics" isNew={newIndicator.Data} />
        </FooterLink>
      </IfWearable>
    </FooterNav>
  );
}
