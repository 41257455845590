import { useRouter } from 'next/router';
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  Grid,
  VisuallyHidden,
} from '@chakra-ui/react';

import {
  ProductEducationPopover,
  useEducationPopoverViewed,
  useGradient,
} from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';

import { AdminDashboard, useAdminDashboard } from '../admin/admin-dashboard';
import { ServiceStatusNotice } from '../error/service-status-notice';
import { useDaysOnStrive } from '../profile/use-days-on-strive';

export type FooterNavProps = {
  sticky?: boolean;
  tabs?: React.ReactNode;
} & BoxProps;

export function FooterNav({
  sticky,
  tabs,
  children,
  ...props
}: FooterNavProps) {
  const { data: user } = useUserProfile();

  const router = useRouter();
  const { data: daysOnStrive } = useDaysOnStrive();
  const sessionTrackerPopoverViewed =
    useEducationPopoverViewed('Daily_Tracker')[0];
  const { isActive: isAdminActive } = useAdminDashboard();
  const navGradient = useGradient('navigation');
  const stickyProps = {
    sx: {
      // Only be sticky if there's enough vertical space
      '@media(min-height: 650px)': {
        position: 'sticky',
        bottom: '0',
      },
    },
  } as const;

  return (
    <chakra.footer
      {...(sticky ? stickyProps : {})}
      {...props}
      mt={-4}
      zIndex={100}
    >
      {tabs}
      <ServiceStatusNotice />
      <Flex
        pointerEvents={'none'}
        as="nav"
        aria-labelledby="strive-app-navigation"
        bg={
          'linear-gradient(180deg, rgba(217, 217, 217, 0.0) 0% ,rgba(217, 217, 217, 0.2) 5.68%, #DBDDE4 45.83%)'
        }
        px={7}
        pb={
          isAdminActive
            ? '16px'
            : 'calc(16px + env(safe-area-inset-bottom, 16px))'
        }
        pt="4"
      >
        <VisuallyHidden>
          <h2 id="strive-app-navigation">Main</h2>
        </VisuallyHidden>

        <ProductEducationPopover
          anchorXPaddingModifier={-4}
          arrowOffsetFromLeft={
            user?.profile.wearable[0]?.provider ? '37%' : undefined
          }
          id={'Toolkit_Tab'}
          placement={'top'}
          shouldRender={
            sessionTrackerPopoverViewed &&
            router.pathname === '/' &&
            Boolean(daysOnStrive && daysOnStrive >= 2)
          }
          title="Toolkit Tab"
          body={`Revisit old tools & see what challenges & tools are coming up!`}
        >
          <Box w={'full'}>
            <Grid
              w={'full'}
              pointerEvents={'all'}
              templateColumns="repeat(auto-fit, minmax(0, 1fr))"
              gap="2"
              width="full"
              px={5}
              bg={navGradient}
              rounded={'full'}
            >
              {children}
            </Grid>
          </Box>
        </ProductEducationPopover>
      </Flex>
      <AdminDashboard />
    </chakra.footer>
  );
}
